import { useState } from 'react';
import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import { getContractBuilderToken } from 'api/contract-builder';
import { info } from 'sitedata';

export default (): [boolean, (location?: string) => void] => {
  const [disableContractBuilderButton, setDisableContractBuilderButton] = useState<boolean>(false);

  const openContractBuilder = (path?: string) => {
    // Fix for Safari
    const windowReference = window.open();

    (async () => {
      setDisableContractBuilderButton(true);
      try {
        const token = await getContractBuilderToken();
        const url = `${info.contractsBuilderUrl}/validate${path || ''}/${token}`;
        (windowReference!.location as any) = url;
      } catch (err) {
        notify(tryGetFirstError(err.response) || err.message);
      }
      setDisableContractBuilderButton(false);
    })();
  };

  return [disableContractBuilderButton, openContractBuilder];
};
