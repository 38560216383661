import React, { useState } from 'react';
import useLayout from 'hooks/useLayout';

interface MainLayoutContextProps {
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MainLayoutContext = React.createContext<MainLayoutContextProps>({} as MainLayoutContextProps);

export const ContextWrapper = ({ children }) => {
  const layout = useLayout();
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(layout === 'desktop');

  const contextValues: MainLayoutContextProps = {
    sidebarOpen,
    setSidebarOpen
  };

  return <MainLayoutContext.Provider value={contextValues}>{children}</MainLayoutContext.Provider>;
};

export default MainLayoutContext;
