import dayjs, { Dayjs } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import dateHolidays from '@date/holidays-us';

dayjs.extend(isoWeek);

const holidays = dateHolidays.bank();

export const isWeekendOrHoliday = (date: Dayjs) => {
  const testDate = dayjs(date).toString();
  const formattedDate = new Date(testDate);
  return Boolean(date.isoWeekday() > 5 || holidays.isHoliday(formattedDate));
};

export const getNextBusinessDay = (date: Dayjs, before: boolean = false) => {
  let newDate = dayjs(date);
  const method = before ? 'subtract' : 'add';
  while (isWeekendOrHoliday(newDate)) newDate = newDate[method](1, 'day');
  return newDate;
};
