import React, { useRef, useContext, useEffect, useState } from 'react';
import { Link } from 'gatsby';

import styled from '@emotion/styled';
import useLayout from 'hooks/useLayout';
import useSitedataAssets from 'hooks/useSitedataAssets';
import useOutsideClick from 'hooks/useOutsideClick';
import useOpenContractBuilder from 'hooks/useOpenContractBuilder';
import Icon from 'components/Icon';
import ToolsPopup from 'components/ToolsPopup';
import MainLayoutContext from '../MainLayoutContext';
import useLinks from './links';

const Sidebar = () => {
  const links = useLinks();
  const { logo } = useSitedataAssets();
  const { sidebarOpen, setSidebarOpen } = useContext(MainLayoutContext);
  const [disableContractBuilderButton, openContractBuilder] = useOpenContractBuilder();
  const [openTools, setOpenTools] = useState(false);
  const layout = useLayout();
  const ref = useRef(null);

  useEffect(() => {
    setSidebarOpen(layout === 'desktop');
  }, [layout, setSidebarOpen]);

  useOutsideClick(ref, () => {
    if (layout === 'tablet' && sidebarOpen) setSidebarOpen(false);
  });

  const renderLink = item => {
    if (item.newTab) {
      return (
        <a
          key={item.path}
          className="sidebar__link"
          href={item.path}
          target="_blank"
          rel="noreferrer">
          {item.text}
        </a>
      );
    }
    if (item.path === 'CONTRACT_BUILDER') {
      return (
        <button
          key={item.path}
          className="sidebar__link"
          onClick={() => openContractBuilder()}
          disabled={disableContractBuilderButton}>
          {item.text}
        </button>
      );
    }
    if (item.path === 'TOOLS') {
      return (
        <button key={item.path} className="sidebar__link" onClick={() => setOpenTools(true)}>
          {item.text}
        </button>
      );
    }
    return (
      <Link key={item.path} className="sidebar__link" to={item.path} activeClassName="active">
        {item.text}
      </Link>
    );
  };

  const displayLink = linksBlock => linksBlock.filter(item => !item.hidden).map(renderLink);

  return (
    <StyledSidebar className={layout} ref={ref}>
      <div className="top-section">
        <Link to="/" className="logo" data-cy="link_logo_home">
          {logo && <img src={logo} alt="logo" />}
        </Link>
        <button
          onClick={() => setSidebarOpen(false)}
          className="button-return"
          data-cy="button_return">
          <Icon name="arrow-simple" />
          <span>Back</span>
        </button>
      </div>
      <div className="sidebar-content">
        {displayLink(links.linksBlock1)}
        <hr />
        {displayLink(links.linksBlock2)}
        <hr />
        {displayLink(links.linksBlock3)}
      </div>
      {openTools && <ToolsPopup onClose={() => setOpenTools(false)} />}
    </StyledSidebar>
  );
};

export default Sidebar;

const StyledSidebar = styled.div`
  height: 100%;
  width: 100%;
  min-width: 200px;
  background: white;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-y: overlay;

  hr {
    border-bottom: 1px solid ${props => props.theme.colors.borderColor};
    margin: 0 0 12px;
  }

  .sidebar {
    &__link {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-decoration: none;
      color: ${props => props.theme.colors.black};
      transition: ${props => props.theme.transitions.standart};
      display: block;
      margin: 0 0 12px;
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;
      outline: none;

      &.active {
        color: ${props => props.theme.colors.red};
      }

      &:disabled {
        color: ${props => props.theme.colors.dustyGray};
        pointer-events: none;
      }

      &:hover {
        color: ${props => props.theme.colors.red};
      }
    }
  }

  .sidebar-content {
    padding: 16px 25px 25px;
  }

  .top-section {
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
    border-bottom: 1px solid #e1e1e1;
    box-sizing: border-box;
    padding: 30px 25px;

    .logo {
      max-width: 100%;

      img {
        width: 100%;
      }
    }
  }

  &.mobile .top-section {
    height: 52px;

    .logo {
      max-width: 150px;
    }
  }

  .button-return {
    font-size: 12px;
    padding: 0;
    background: none;
    border: none;
    margin-left: auto;
    cursor: pointer;
    align-items: center;
    display: flex;

    .icon {
      margin: 0 5px 1px 0;
    }

    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      display: none;
    }
  }
`;
