import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import dayjs, { Dayjs } from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';

import { Button, InputDate } from '@xchange/uikit';

dayjs.extend(dayOfYear);

type FormValues = {
  calendarDay: Dayjs;
};

const getNumberWithOrdinal = n => {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

const DayOfYearItem = () => {
  const [result, setResult] = useState<Dayjs>();
  const { control, handleSubmit, formState: { isDirty, isValid } } = useForm({
    mode: 'onChange'
  });

  const submit = async (values: FormValues) => {
    setResult(dayjs(values.calendarDay));
  };

  return (
    <div className="day-of-year">
      <form onSubmit={handleSubmit(submit)} className="tools-popup-form">
        <Controller
          control={control}
          rules={{
            required: 'Required',
            validate: value => dayjs(value).isValid() || `Invalid date`
          }}
          defaultValue=""
          name="calendarDay"
          render={({ field, formState: { errors } }) => (
            <InputDate
              inputProps={{
                'data-cy': 'calendarDay',
                label: 'Calendar Day',
                placeholder: 'Select',
                error: errors.calendarDay?.message
              }}
              className="tools-popup-form__datepicker tools-popup-form__item"
              {...field}
            />
          )}
        />
        <Button
          type="submit"
          disabled={!isValid || !isDirty}
          className="tools-popup-form__button"
          large
          data-cy="button_submit">
          Calculate
        </Button>
      </form>
      {result && (
        <div data-cy="day_of_year_results" className="tools-popup-form__results">
          <p className="tools-popup-form__results-text">{result.format('dddd, MMMM D, YYYY')}</p>
          <p className="tools-popup-form__results-text">
            <strong>{getNumberWithOrdinal(result.dayOfYear())} day of the year</strong>
          </p>
        </div>
      )}
    </div>
  );
};

export default DayOfYearItem;
